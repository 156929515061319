import React from 'react';
import { navigate } from 'gatsby';
import { useCallback } from 'react';

const AnimeLink: React.FC<{
  to: string;
  className?: string;
}> = ({ children, to, className = '' }) => {
  const onClick = useCallback(() => {
    const event = new CustomEvent('__onAnimeMoveRequest', {
      detail: {
        to: to,
        duration: 300,
      },
    });
    window.dispatchEvent(event);
    // navigate(to);
    // pageAnime('start').then(() => {
    //   navigate(to);
    // });
  }, [to]);
  return (
    <a
      className={className}
      href={to}
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
    >
      {children}
    </a>
  );
};

export default AnimeLink;
