import React, { useCallback, useContext, useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import '../styles/main.scss';
import { PageProps, navigate } from 'gatsby';
import ArticleBody from './works/ArticleBody';
import animejs from 'animejs';

declare global {
  interface WindowEventMap {
    __onRouteUpdate: CustomEvent<PageLoc>;
    __onPreRouteUpdate: CustomEvent<PageLoc>;
    __onAnimeMoveRequest: CustomEvent<AnimeMoveRequest>;
  }
  interface Window {
    __pageLoc?: PageLoc;
  }
}

export interface PageLoc {
  location?: Location;
  prevLocation?: Location;
}

export interface AnimeMoveRequest {
  to: string;
  duration: number;
}

const Layout: React.FC<{
  pageProps: PageProps;
}> = ({ children, pageProps }) => {
  useEffect(() => {
    window.addEventListener('__onRouteUpdate', (event) => {
      const prevLocation = event.detail ? event.detail.prevLocation : null;
      document.body.classList.add('non_scroll');
      const animeParam: animejs.AnimeParams = {
        targets: 'main.body',
        opacity: [0, 1],
        translateX: ['30px', 0],
        overflow: ['hidden auto', 'auto'],
        duration: 300,
        easing: 'easeOutQuart',
        complete: () => {
          document.body.classList.remove('non_scroll');
        },
      };
      if (prevLocation && prevLocation.pathname === '/') {
        animeParam.translateX = [0, 0];
      }
      animejs(animeParam);
    });

    window.addEventListener('__onAnimeMoveRequest', (event) => {
      const animeParam: animejs.AnimeParams = {
        targets: 'main.body',
        opacity: 0,
        translateX: '-30px',
        duration: event.detail.duration,
        easing: 'easeOutQuart',
        complete: () => {
          navigate(event.detail.to);
        },
      };
      if (event.detail.to === '/') {
        animeParam.translateX = 0;
      } else if (window.__pageLoc.location.pathname === '/') {
        animeParam.complete = null;
        navigate(event.detail.to);
      }
      animejs(animeParam);
    });
  }, []);
  return (
    <>
      <Header currentPath={pageProps.location.pathname} />
      <>
        {/^\/works\/.+/.test(pageProps.path) ? (
          <ArticleBody data={pageProps.data} />
        ) : (
          children
        )}
      </>

      <Footer />
    </>
  );
};

export default Layout;
