const React = require('react');
const { default: Layout } = require('./src/components/Layout');
const { default: Header } = require('./src/components/Header');

exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  const event = new CustomEvent('__onPreRouteUpdate', {
    detail: {
      location,
      prevLocation,
    },
  });
  window.dispatchEvent(event);
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  const event = new CustomEvent('__onRouteUpdate', {
    detail: {
      location,
      prevLocation,
    },
  });
  window.dispatchEvent(event);
  window.__pageLoc = event.detail;
};

const WrapPageElement = ({ element, props }) => {
  return (
    <>
      <Layout pageProps={props}>{element}</Layout>
    </>
  );
};

exports.wrapPageElement = WrapPageElement;
