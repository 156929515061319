import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const Head: React.VFC<{
  title?: string;
  description?: string;
  ogType?: string;
  ogImageUrl?: string;
}> = ({ title, description, ogType, ogImageUrl }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          ogImageUrl
        }
      }
    }
  `);
  const metaDescription = description || site.siteMetadata.description;
  const pageTitle = title
    ? title + ' | ' + site.siteMetadata.title
    : site.siteMetadata.title;
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'ja',
        }}
        title={pageTitle}
      >
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:type" content={ogType || 'website'} />
        <meta
          property="og:image"
          content={ogImageUrl || site.siteMetadata.ogImageUrl}
        />
        <meta name="twitter:card" content="summary" />
      </Helmet>
    </>
  );
};

export default Head;
