import { PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useCallback } from 'react';
import { useMemo } from 'react';
import Head from '../Head';
import { PageLoc } from '../Layout';

const ArticleBody: React.VFC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}> = ({ data }) => {
  const image = useMemo(() => {
    if (!data.markdownRemark || !data.markdownRemark.frontmatter) return null;
    return getImage(data.markdownRemark.frontmatter.thumbnail);
  }, [data]);

  const workData = useMemo(() => {
    if (!data.markdownRemark || !data.markdownRemark.frontmatter) return null;
    return data.markdownRemark.frontmatter;
  }, [data]);

  const html = useMemo(() => {
    if (!data.markdownRemark) return null;
    return data.markdownRemark.html;
  }, [data]);

  const getDisplayDate = useCallback(() => {
    if (!workData) return '';
    const o = new Intl.DateTimeFormat('ja', {
      dateStyle: 'short',
    });
    return o.format(new Date(workData.date));
  }, [workData]);

  return (
    <>
      {workData && (
        <Head
          title={workData.title}
          description={workData.description}
          ogImageUrl={
            workData.thumbnail
              ? data.site.siteMetadata.siteUrl + workData.thumbnail.publicURL
              : null
          }
        />
      )}

      <main className={'body'}>
        <article>
          <header>
            <time dateTime={workData.date}>{getDisplayDate()}</time>
            <h1>{workData.title}</h1>
            <div className="work-image">
              {image && <GatsbyImage image={image} alt="サムネイル" />}
            </div>
          </header>
          {html && (
            <section
              className="article-body"
              dangerouslySetInnerHTML={{ __html: html }}
            ></section>
          )}
        </article>
      </main>
    </>
  );
};

export default ArticleBody;
