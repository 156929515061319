import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import AnimeLink from './AnimeLink';
import {} from './Layout';

interface HeaderLink {
  name: string;
  to: string;
  active: boolean;
}

function isCurrentLink(currentPath: string, target: string) {
  return currentPath.replace(/\/$/, '') === target.replace(/\/$/, '');
}

const generateHeaderLinks = (currentPath: string): HeaderLink[] => [
  {
    name: 'Works',
    to: '/works',
    active: isCurrentLink(currentPath, '/works'),
  },
  {
    name: 'Profile',
    to: '/profile',
    active: isCurrentLink(currentPath, '/profile'),
  },
  {
    name: 'Contact',
    to: '/contact',
    active: isCurrentLink(currentPath, '/contact'),
  },
];

const Header: React.VFC<{
  currentPath?: string;
}> = ({ currentPath }) => {
  const [isTop, setIsTop] = useState<boolean>(currentPath === '/');

  useEffect(() => {
    window.addEventListener('__onAnimeMoveRequest', (event) => {
      if (event.detail.to === '/') {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    });
  }, []);
  useEffect(() => {
    if (currentPath === '/') {
      setIsTop(true);
    } else {
      setIsTop(false);
    }
  }, [currentPath]);
  return (
    <>
      <header className={'header' + (isTop ? ' top' : '')}>
        {isTop && <h1 className="title">TotoraJ.NET</h1>}
        {!isTop && (
          <p className="title">
            <AnimeLink to="/">TotoraJ.NET</AnimeLink>
          </p>
        )}
        <ul>
          {generateHeaderLinks(currentPath || '').map((item) => (
            <li key={item.name}>
              <AnimeLink
                to={item.to}
                className={item.active && !isTop ? 'active' : ''}
              >
                {item.name}
                <span className="cross left"></span>
                <span className="cross right"></span>
              </AnimeLink>
            </li>
          ))}
        </ul>
      </header>
    </>
  );
};

export default Header;
