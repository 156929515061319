import React from 'react';

const Footer: React.VFC = () => {
  return (
    <>
      <footer className="footer">
        <p>
          &copy; 2021 totoraj. All rights reserved. This site was designed by
          totoraj.
        </p>
      </footer>
    </>
  );
};

export default Footer;
